import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'my-store',
    // redirectTo: 'layout/theme',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'forget-password',
    loadChildren: () => import('./pages/forget-password/forget-password.module').then( m => m.ForgetPasswordPageModule)
  },
  {
    path: 'blank',
    loadChildren: () => import('./pages/blank/blank.module').then( m => m.BlankPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'my-store',
    loadChildren: () => import('./pages/my-store/my-store.module').then( m => m.MyStorePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pos',
    loadChildren: () => import('./pages/pos/pos.module').then( m => m.PosPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'setting',
    children: [
      {
        path: 'normal-setting',
        loadChildren: () => import('./pages/normal-setting/normal-setting.module').then( m => m.NormalSettingPageModule)
      },
      {
        path: 'payment-setting',
        loadChildren: () => import('./pages/payment-setting/payment-setting.module').then( m => m.PaymentSettingPageModule)
      },
      {
        path: 'delivery-setting',
        loadChildren: () => import('./pages/delivery-setting/delivery-setting.module').then( m => m.DeliverySettingPageModule)
      },
      {
        path: 'promotion-setting',
        loadChildren: () => import('./pages/promotion-setting/promotion-setting.module').then( m => m.PromotionSettingPageModule)
      },
      {
        path: '',
        redirectTo: 'normal-setting',
        pathMatch: 'full'
      }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'normal-setting',
    loadChildren: () => import('./pages/normal-setting/normal-setting.module').then( m => m.NormalSettingPageModule)
  },
  {
    path: 'payment-setting',
    loadChildren: () => import('./pages/payment-setting/payment-setting.module').then( m => m.PaymentSettingPageModule)
  },
  {
    path: 'delivery-setting',
    loadChildren: () => import('./pages/delivery-setting/delivery-setting.module').then( m => m.DeliverySettingPageModule)
  },
  {
    path: 'promotion-setting',
    loadChildren: () => import('./pages/promotion-setting/promotion-setting.module').then( m => m.PromotionSettingPageModule)
  },
  {
    path: 'product',
    children: [
      {
        path: 'product-list',
        loadChildren: () => import('./pages/product-list/product-list.module').then( m => m.ProductListPageModule)
      },
      {
        path: 'product-detail',
        loadChildren: () => import('./pages/product-detail/product-detail.module').then( m => m.ProductDetailPageModule)
      },
      {
        path: 'product-sorting',
        loadChildren: () => import('./pages/product-sorting/product-sorting.module').then( m => m.ProductSortingPageModule)
      },
      {
        path: 'additional-product-list',
        loadChildren: () => import('./pages/additional-product-list/additional-product-list.module').then( m => m.AdditionalProductListPageModule)
      },
      {
        path: 'additional-product-detail',
        loadChildren: () => import('./pages/additional-product-detail/additional-product-detail.module').then( m => m.AdditionalProductDetailPageModule)
      },
      {
        path: 'product-category',
        loadChildren: () => import('./pages/product-category/product-category.module').then( m => m.ProductCategoryPageModule)
      },
      {
        path: 'product-category-list',
        loadChildren: () => import('./pages/product-category-list/product-category-list.module').then( m => m.ProductCategoryListPageModule)
      },
      {
        path: 'product-category-sorting',
        loadChildren: () => import('./pages/product-category-sorting/product-category-sorting.module').then( m => m.ProductCategorySortingPageModule)
      },
      {
        path: '',
        redirectTo: 'product-list',
        pathMatch: 'full'
      }
    ],
    canActivate: [AuthGuard]
  },{
    path: 'medi-appointment',
    children:[
      {
        path: 'medi-appointment-category',
        loadChildren: () => import('./pages/medi-appointment-category/medi-appointment-category.module').then( m => m.MediAppointmentCategoryPageModule)
      },
      {
        path: 'medi-appointment-record',
        loadChildren: () => import('./pages/medi-appointment-record/medi-appointment-record.module').then( m => m.MediAppointmentRecordPageModule)
      },
      {
        path: 'medi-appointment-record-detail',
        loadChildren: () => import('./pages/medi-appointment-record-detail/medi-appointment-record-detail.module').then( m => m.MediAppointmentRecordDetailPageModule)
      },
      {
        path: 'medi-appointment-list',
        loadChildren: () => import('./pages/medi-appointment-list/medi-appointment-list.module').then( m => m.MediAppointmentListPageModule)
      },
      {
        path: 'medi-appointment-detail',
        loadChildren: () => import('./pages/medi-appointment-detail/medi-appointment-detail.module').then( m => m.MediAppointmentDetailPageModule)
      },
      {
        path: 'medi-appointment-sorting',
        loadChildren: () => import('./pages/medi-appointment-sorting/medi-appointment-sorting.module').then( m => m.MediAppointmentSortingPageModule)
      },
      {
        path: '',
        redirectTo: 'medi-appointment-list',
        pathMatch: 'full'
      }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'appointment',
    children:[
      {
        path: 'appointment-category',
        loadChildren: () => import('./pages/appointment-category/appointment-category.module').then( m => m.AppointmentCategoryPageModule)
      },
      {
        path: 'appointment-record',
        loadChildren: () => import('./pages/appointment-record/appointment-record.module').then( m => m.AppointmentRecordPageModule)
      },
      {
        path: 'appointment-record-detail',
        loadChildren: () => import('./pages/appointment-record-detail/appointment-record-detail.module').then( m => m.AppointmentRecordDetailPageModule)
      },
      {
        path: 'appointment-list',
        loadChildren: () => import('./pages/appointment-list/appointment-list.module').then( m => m.AppointmentListPageModule)
      },
      {
        path: 'appointment-detail',
        loadChildren: () => import('./pages/appointment-detail/appointment-detail.module').then( m => m.AppointmentDetailPageModule)
      },
      {
        path: 'appointment-sorting',
        loadChildren: () => import('./pages/appointment-sorting/appointment-sorting.module').then( m => m.AppointmentSortingPageModule)
      },
      {
        path: '',
        redirectTo: 'appointment-list',
        pathMatch: 'full'
      }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'order',
    children: [
      {
        path: 'order-list',
        loadChildren: () => import('./pages/order-list/order-list.module').then( m => m.OrderListPageModule)
      },
      {
        path: 'order-detail',
        loadChildren: () => import('./pages/order-detail/order-detail.module').then( m => m.OrderDetailPageModule)
      },
      {
        path: '',
        redirectTo: 'order-list',
        pathMatch: 'full'
      }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'user',
    children: [
      {
        path: 'user-list',
        loadChildren: () => import('./pages/user-list/user-list.module').then( m => m.UserListPageModule)
      },
      {
        path: 'user-detail',
        loadChildren: () => import('./pages/user-detail/user-detail.module').then( m => m.UserDetailPageModule)
      },
      {
        path: '',
        redirectTo: 'user-list',
        pathMatch: 'full'
      }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'promotion',
    children: [
      {
        path: 'coupon-list',
        loadChildren: () => import('./pages/coupon-list/coupon-list.module').then( m => m.CouponListPageModule)
      },
      {
        path: 'coupon-detail',
        loadChildren: () => import('./pages/coupon-detail/coupon-detail.module').then( m => m.CouponDetailPageModule)
      },
      {
        path: '',
        redirectTo: 'coupon-list',
        pathMatch: 'full'
      }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'medi-enquiry',
    children:[
      {
        path: 'medi-enquiry-list',
        loadChildren: () => import('./pages/medi-enquiry-list/medi-enquiry-list.module').then( m => m.MediEnquiryListPageModule)
      },
      {
        path: 'medi-enquiry-detail',
        loadChildren: () => import('./pages/medi-enquiry-detail/medi-enquiry-detail.module').then( m => m.MediEnquiryDetailPageModule)
      },
      {
        path: '',
        redirectTo: 'medi-enquiry-list',
        pathMatch: 'full'
      }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'enquiry',
    children:[
      {
        path: 'enquiry-list',
        loadChildren: () => import('./pages/enquiry-list/enquiry-list.module').then( m => m.EnquiryListPageModule)
      },
      {
        path: 'enquiry-detail',
        loadChildren: () => import('./pages/enquiry-detail/enquiry-detail.module').then( m => m.EnquiryDetailPageModule)
      },
      {
        path: '',
        redirectTo: 'enquiry-list',
        pathMatch: 'full'
      }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'layout',
    children: [
      // {
      //   path: 'theme',
      //   loadChildren: () => import('./pages/theme/theme.module').then( m => m.ThemePageModule)
      // },
      {
        path: 'home-setting',
        loadChildren: () => import('./pages/home-setting/home-setting.module').then( m => m.HomeSettingPageModule)
      },
      {
        path: 'menu',
        loadChildren: () => import('./pages/menu/menu.module').then( m => m.MenuPageModule)
      },
      // {
      //   path: 'custom-page',
      //   loadChildren: () => import('./pages/custom-page/custom-page.module').then( m => m.CustomPagePageModule)
      // },
      {
        path: '',
        redirectTo: 'home-setting',
        pathMatch: 'full'
      }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'course',
    children: [
      {
        path: 'course-list',
        loadChildren: () => import('./pages/course-list/course-list.module').then( m => m.CourseListPageModule)
      },
      {
        path: 'course-detail',
        loadChildren: () => import('./pages/course-detail/course-detail.module').then( m => m.CourseDetailPageModule)
      },
      {
        path: 'course-category-list',
        loadChildren: () => import('./pages/course-category-list/course-category-list.module').then( m => m.CourseCategoryListPageModule)
      },
      {
        path: '',
        redirectTo: 'course-list',
        pathMatch: 'full'
      }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'staff-store-list',
    loadChildren: () => import('./pages/staff-store-list/staff-store-list.module').then( m => m.StaffStoreListPageModule)
  },
  {
    path: 'staff-store-detail',
    loadChildren: () => import('./pages/staff-store-detail/staff-store-detail.module').then( m => m.StaffStoreDetailPageModule)
  },
  {
    path: 'internal-enquiry',
    loadChildren: () => import('./pages/internal-enquiry/internal-enquiry.module').then( m => m.InternalEnquiryPageModule)
  },
  {
    path: 'internal-enquiry-detail',
    loadChildren: () => import('./pages/internal-enquiry-detail/internal-enquiry-detail.module').then( m => m.InternalEnquiryDetailPageModule)
  },
  {
    path: 'internal-quotation',
    loadChildren: () => import('./pages/internal-quotation/internal-quotation.module').then( m => m.InternalQuotationPageModule)
  },
  {
    path: 'internal-quotation-detail',
    loadChildren: () => import('./pages/internal-quotation-detail/internal-quotation-detail.module').then( m => m.InternalQuotationDetailPageModule)
  },
  {
    path: 'internal-plan-purchase',
    loadChildren: () => import('./pages/internal-plan-purchase/internal-plan-purchase.module').then( m => m.InternalPlanPurchasePageModule)
  },
  {
    path: 'internal-plan-purchase-detail',
    loadChildren: () => import('./pages/internal-plan-purchase-detail/internal-plan-purchase-detail.module').then( m => m.InternalPlanPurchaseDetailPageModule)
  },
  {
    path: 'internal-emailbox',
    loadChildren: () => import('./pages/internal-emailbox/internal-emailbox.module').then( m => m.InternalEmailboxPageModule)
  },
  {
    path: '**',
    redirectTo: 'my-store',
    // redirectTo: 'layout/theme',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
